import logo from '../images/logos/logo.svg'
import Image from "next/image";

export function Logo(props) {
    return <Image
        className="h-10 w-auto"
        src={logo}
        alt="Bijlesplein logo"
        priority
        {...props}
    />
}
