import {Fragment, useEffect, useState} from 'react'
import Link from 'next/link'
import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'

import { Button } from '@/components/Button'
import { Container } from '@/components/Container'
import { Logo } from '@/components/Logo'
import { NavLink } from '@/components/NavLink'

const links = [
  {label: 'Voordelen', link: '/#waarom-bijlesplein'},
  {label: 'Hoe werkt het?', link: '/#functies'},
  {label: 'Kansenongelijkheid', link: '/kansenongelijkheid'},
  {label: 'In de praktijk', link: '/cases'},
  {label: 'Over ons', link: '/over-ons'},
  {label: 'Tarieven', link: '/#tarieven'},
]

function MobileNavLink({ href, children }) {
  return (
    <Popover.Button as={Link} href={href} className="block w-full p-2">
      {children}
    </Popover.Button>
  )
}

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0'
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0'
        )}
      />
    </svg>
  )
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            {links.map((link, index) => <MobileNavLink key={index} href={link.link}>{link.label}</MobileNavLink>)}
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

export function Header() {
  const [atTop, setAtTop] = useState(true);

  useEffect(() => {
    function updatePosition() {
      setAtTop((window.pageYOffset > 10) ? false : true);
    }

    window.addEventListener('scroll', updatePosition);

    updatePosition();

    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return <>
    <header className={clsx("py-4 lg:py-6 fixed w-full z-40 bg-white transition-colors transition-shadow", !atTop && 'bg-white shadow-xl')}>
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center lg:gap-x-4">
            <Link href="/" aria-label="Home">
              <Logo className="h-8 sm:h-12 w-auto" />
            </Link>
            <div className="hidden lg:flex lg:gap-x-2">
              {links.map((link, index) => <NavLink key={index} href={link.link}>{link.label}</NavLink>)}
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <Button href="/contact" color="white" contact contactMobile/>
            <Button href="/demo" color="blue">
              <span className="capitalize xl:normal-case"><span className="hidden xl:inline">Gratis</span> demo</span>
            </Button>
            <div className="-mr-1 lg:hidden">
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </Container>
    </header>
    <div className="h-20"></div>
  </>
}
